import { render, staticRenderFns } from "./pendamping-pmptsp.vue?vue&type=template&id=4da7b5cd&scoped=true"
import script from "./pendamping-pmptsp.vue?vue&type=script&lang=js"
export * from "./pendamping-pmptsp.vue?vue&type=script&lang=js"
import style0 from "./pendamping-pmptsp.vue?vue&type=style&index=0&id=4da7b5cd&prod&lang=scss"
import style1 from "./pendamping-pmptsp.vue?vue&type=style&index=1&id=4da7b5cd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4da7b5cd",
  null
  
)

export default component.exports
<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!-- <div class="mt-1 mb-1 ml-1">
                <b-button variant="primary"><i class="fa fa-download" aria-hidden="true" />Export PM PTSP Pemda(2021)
                </b-button>
                <b-button variant="primary" class="ml-1"><i class="fa fa-download" aria-hidden="true" />Export PM PTSP
                    Pemda(2022) </b-button>
            </div> -->
      <!--begin: Wizard-->
      <div
        class="wizard wizard-verval"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">Kelembagaan</div>
                  <div class="wizard-desc">Penilaian Kelembagaan</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">2</div>
                <div class="wizard-label">
                  <div class="wizard-title">SDM</div>
                  <div class="wizard-desc">Penilaian Sumber Daya Manusia</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">3</div>
                <div class="wizard-label">
                  <div class="wizard-title">Sarpras</div>
                  <div class="wizard-desc">Penilaian Sarana dan Prasarana</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">4</div>
                <div class="wizard-label">
                  <div class="wizard-title">OSS</div>
                  <div class="wizard-desc">Penilaian Implementasi OSS</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-number">5</div>
                <div class="wizard-label">
                  <div class="wizard-title">Keluaran</div>
                  <div class="wizard-desc">Penilaian Keluaran</div>
                </div>
              </div>
            </div>
            <div class="wizard-step" data-wizard-type="step">
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <div class="wizard-title">Persetujuan Berita Acara</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-12 px-0 py-lg-15 px-lg-0">
              <div class="col-xl-12 col-xxl-11">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Kelembagaan PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Integritas dan Kepatuhan</b>
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Apakah tersedia SOP Pelayanan Perizinan Berusaha?
                            </td>
                            <td colspan="5">
                              Untuk menilai ketersediaan SOP Pelayanan Perizinan
                              Berusaha dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha telah
                                  ditetapkan melalui Keputusan Kepala Daerah
                                  atau Keputusan Kepala DPMPTSP
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a1_nilai',
                                      dataVerlap.p1a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a1_nilai_status
                                    ),
                                  }"
                                  :options="option1"
                                  disabled
                                  v-model="dataQC.p1a1_nilai"
                                  @change="
                                    submitQC('p1a1_nilai', dataQC.p1a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha mencakup
                                  seluruh bidang usaha (KBLI) yang menjadi
                                  kewenangannya; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP pelayanan perizinan berusaha dapat diakses
                                  oleh publik secara konvensional atau digital.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, lengkap” apabila ketiga
                                    ketentuan di atas terpenuhi.<br />
                                    2. Nilai “Ada, tidak lengkap” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Seberapa patuh terhadap NSPK?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Penilaian kepatuhan terhadap NSPK, Peraturan
                                  Kepala Daerah (Perkada) yang telah diterbitkan
                                  dengan mengacu pada PP. No. 5 Tahun 2021 dan
                                  PP. No. 6 Tahun 2021.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option2"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a2_nilai',
                                      dataVerlap.p1a2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option2"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a2_nilai_status
                                    ),
                                  }"
                                  :options="option2"
                                  disabled
                                  v-model="dataQC.p1a2_nilai"
                                  @change="
                                    submitQC('p1a2_nilai', dataQC.p1a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Patuh” apabila ketentuan di atas
                                    terpenuhi.
                                    <br />
                                    2. Nilai “Tidak patuh” apabila ketentuan di
                                    atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Apakah ada dokumen Standar Pelayanan Publik (SPP)?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya dokumen Standar Pelayanan
                              Publik [SPP] dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen [SPP] ditetapkan melalui Keputusan
                                  Kepala Daerah atau Keputusan Kepala DPMPTSP;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option1"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a3_nilai',
                                      dataVerlap.p1a3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option1"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a3_nilai_status
                                    ),
                                  }"
                                  :options="option1"
                                  disabled
                                  v-model="dataQC.p1a3_nilai"
                                  @change="
                                    submitQC('p1a3_nilai', dataQC.p1a3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen [SPP] mengacu pada Permenpan RB No. 15
                                  Tahun 2014 tentang Pedoman Standar Pelayanan;
                                  dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a3_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen [SPP] dapat diakses oleh publik secara
                                  konvensional atau digital.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a3_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 4 -->
                          <tr>
                            <td rowspan="4">4</td>
                            <td rowspan="4">
                              Apakah tersedia Pakta Integritas?
                            </td>
                            <td colspan="4">
                              Penilaian ketersediaan Pakta Integritas dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pakta Integritas tersedia di ruang pelayanan;
                                  dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a4_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1a4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1a4_nilai',
                                      dataVerlap.p1a4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1a4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1a4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1a4_nilai_status
                                    ),
                                  }"
                                  :options="option3"
                                  disabled
                                  v-model="dataQC.p1a4_nilai"
                                  @change="
                                    submitQC('p1a4_nilai', dataQC.p1a4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pejabat tertinggi yang membidangi pelayanan
                                  perizinan berusaha memiliki Pakta Integritas.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1a4_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1a4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- kewenangan -->
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Kewenangan</b>
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">
                              Apakah ada pelimpahan kewenangan?
                            </td>
                            <td colspan="4">
                              Penilaian ada tidaknya pelimpahan kewenangan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumen pelimpahan kewenangan sebagai
                                  berikut: untuk pemerintah provinsi,
                                  pendelegasian kewenangan oleh gubernur
                                  meliputi:
                                  <table border="0">
                                    <tr>
                                      <td>a.</td>
                                      <td colspan="2">
                                        Untuk pemerintah provinsi, pendelegasian
                                        kewenangan oleh gubernur meliputi:
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">1)</td>
                                      <td>
                                        Penyelenggaraan Perizinan Berusaha
                                        seluruh KBLI yang menjadi kewenangan
                                        pemerintah daerah provinsi sesuai dengan
                                        ketentuan peraturan perundang-undangan;
                                        dan
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">2)</td>
                                      <td>
                                        Penyelenggaraan Perizinan Berusaha
                                        seluruh KBLI yang menjadi kewenangan
                                        Pemerintah Pusat yang dilimpahkan kepada
                                        gubernur berdasarkan asas dekonsentrasi
                                        dan tugas pembantuan.
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>b.</td>
                                      <td colspan="2">
                                        Untuk pemerintah kabupaten/kota,
                                        pendelegasian kewenangan oleh
                                        bupati/wali kota meliputi:
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">1)</td>
                                      <td>
                                        Penyelenggaraan Perizinan Berusaha
                                        seluruh KBLI yang menjadi kewenangan
                                        Pemerintah Daerah kabupaten/kota sesuai
                                        dengan ketentuan peraturan
                                        perundang-undangan; dan
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">2)</td>
                                      <td>
                                        Penyelenggaraan Perizinan Berusaha
                                        seluruh KBLI yang menjadi kewenangan
                                        Pemerintah Pusat yang dilimpahkan kepada
                                        bupati/wali kota berdasarkan asas tugas
                                        pembantuan.
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1b1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>

                            <td rowspan="1">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option4"
                                  style="min-width: 150px"
                                  v-model="datapm.p1b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1b1_nilai',
                                      dataVerlap.p1b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option4"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1b1_nilai_status
                                    ),
                                  }"
                                  :options="option4"
                                  disabled
                                  v-model="dataQC.p1b1_nilai"
                                  @change="
                                    submitQC('p1b1_nilai', dataQC.p1b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Meliputi seluruh perizinan berusaha yang
                                  menjadi kewenangan pemerintah daerah provinsi,
                                  dan pemerintah daerah kabupaten/kota.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1b1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada sepenuhnya” apabila kedua
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada sebagian” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">Apakah ada target retribusi?</td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Penilaian terkait ada tidak adanya target
                                  retribusi melalui surat pernyataan Kadis
                                  DPMPTSP terkait
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1b2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>

                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option5"
                                  style="min-width: 150px"
                                  v-model="datapm.p1b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1b2_nilai',
                                      dataVerlap.p1b2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option5"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1b2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1b2_nilai_status
                                    ),
                                  }"
                                  :options="option5"
                                  disabled
                                  v-model="dataQC.p1b2_nilai"
                                  @change="
                                    submitQC('p1b2_nilai', dataQC.p1b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penilaian ada tidaknya target retribusi
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Tidak ada” apabila pemerintah
                                    daerah dapat membuktikan tentang tidak
                                    adanya target retribusi dengan
                                    sekurang-kurangnya melalui surat pernyataan
                                    dari Kepala DPMPTSP. <br />
                                    2. Nilai “Ada” apabila ketentuan di atas
                                    tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- Tanggung Jawab -->
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Tanggung Jawab</b>
                            </td>
                          </tr>

                          <!-- 1 -->

                          <tr>
                            <td rowspan="4">1</td>
                            <td rowspan="4">Apakah ada mekanisme pengaduan?</td>
                            <td colspan="5">
                              Penilaian ada tidaknya mekanisme pengaduan
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumen SOP layanan pengaduan yang
                                  dapat diakses dengan penggunaan teknologi
                                  informasi dan komunikasi atau secara
                                  konvensional, misalnya:
                                  <em>flyer</em>, brosur,
                                  <em>standing banner</em>, dll.; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c1_nilai',
                                      dataVerlap.p1c1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c1_nilai_status
                                    ),
                                  }"
                                  :options="option3"
                                  disabled
                                  v-model="dataQC.p1c1_nilai"
                                  @change="
                                    submitQC('p1c1_nilai', dataQC.p1c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat layanan pengaduan terjadwal.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">
                              Bagaimana tingkat penyelesaian pengaduan?
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Daftar pengaduan dan tingkat penyelesaiannya
                                </div>
                              </div>
                            </td>
                            <td>
                              <a :href="dataVerlap.p1c2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option6"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c2_nilai',
                                      dataVerlap.p1c2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option6"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c2_nilai_status
                                    ),
                                  }"
                                  :options="option6"
                                  disabled
                                  v-model="dataQC.p1c2_nilai"
                                  @change="
                                    submitQC('p1c2_nilai', dataQC.p1c2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penilaian tingkat penyelesaian pengaduan
                                    dilakukan dengan ketentuan: <br />
                                    1. Nilai “Semua selesai” apabila jumlah
                                    pengaduan yang masuk sebanding dengan jumlah
                                    pengaduan yang diselesaikan. <br />
                                    2. Nilai “Sebagian selesai” apabila jumlah
                                    pengaduan yang masuk diselesaikan sebagian.
                                    <br />
                                    3. Nilai “Tidak ada yang selesai” apabila
                                    tidak ada pengaduan yang telah diselesaikan.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->

                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Seberapa cepat waktu penyelesaian masalah atau
                              pengaduan?
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen daftar pengaduan yang masuk, tingkat
                                  penyelesaian, dan halaman waktu penyelesaian
                                  di SOP nya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option7"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c3_nilai',
                                      dataVerlap.p1c3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option7"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c3_nilai_status
                                    ),
                                  }"
                                  :options="option7"
                                  disabled
                                  v-model="dataQC.p1c3_nilai"
                                  @change="
                                    submitQC('p1c3_nilai', dataQC.p1c3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penilaian kecepatan waktu penyelesaian
                                    masalah atau pengaduan dilakukan dengan
                                    ketentuan: <br />
                                    1. Nilai “lebih cepat dari SOP” apabila
                                    waktu rata-rata penyelesaian masalah atau
                                    pengaduan lebih cepat daripada waktu yang
                                    dimuat dalam SOP. <br />
                                    2. Nilai “Sesuai SOP” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan sama dengan waktu yang dimuat
                                    dalam SOP. <br />
                                    3. Nilai “Tidak sesuai” apabila waktu
                                    rata-rata penyelesaian masalah atau
                                    pengaduan lebih lama daripada waktu yang
                                    dimuat dalam SOP.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 4 -->

                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Apakah ada layanan <em>helpdesk</em> ?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya <em>helpdesk</em> dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Layanan <em>helpdesk</em> dapat diakses dengan
                                  menggunakan teknologi informasi dan komunikasi
                                  atau konvensional;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c4_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1c4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1c4_nilai',
                                      dataVerlap.p1c4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1c4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1c4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1c4_nilai_status
                                    ),
                                  }"
                                  :options="option3"
                                  disabled
                                  v-model="dataQC.p1c4_nilai"
                                  @change="
                                    submitQC('p1c4_nilai', dataQC.p1c4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  <em>Helpdesk</em> secara konvensional memiliki
                                  ruang/loket dan petugas tersendiri.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c4_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1c4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Layanan <em>helpdesk</em> diberikan setiap
                                  hari kerja.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1c4_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1c4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila ketiga ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- Tanggung Jawab -->
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Kesinambungan</b>
                            </td>
                          </tr>
                          <!-- 1 -->

                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan koordinasi internal?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya kegiatan koordinasi
                              internal dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan koordinasi internal dilakukan
                                  sekurang-kurangnya 1 kali dalam 1 bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option8"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d1_nilai',
                                      dataVerlap.p1d1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option8"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d1_nilai_status
                                    ),
                                  }"
                                  :options="option8"
                                  disabled
                                  v-model="dataQC.p1d1_nilai"
                                  @change="
                                    submitQC('p1d1_nilai', dataQC.p1d1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula kegiatan koordinasi internal.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d1_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat dokumentasi rapat dalam bentuk foto.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d1_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila kelima
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">
                              Berapa nilai Indeks Kepuasan Masyarakat (IKM)?
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen hasil survei IKM
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option9"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d2_nilai',
                                      dataVerlap.p1d2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option9"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d2_nilai_status
                                    ),
                                  }"
                                  :options="option9"
                                  disabled
                                  v-model="dataQC.p1d2_nilai"
                                  @change="
                                    submitQC('p1d2_nilai', dataQC.p1d2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Di atas 75” apabila IKM
                                    mendapatkan hasil di atas 75. <br />
                                    2. Nilai “Di bawah 75” apabila IKM
                                    mendapatkan hasil di bawah 75. <br />
                                    3. Nilai “Tidak ada” apabila tidak dilakukan
                                    survei IKM.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->

                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana tingkat kecukupan dan kualitas kegiatan
                              inovasi?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kualitas kegiatan inovasi
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan inovasi tersedia lebih dari 2 bentuk
                                  inovasi;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option10"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d3_nilai',
                                      dataVerlap.p1d3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option10"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d3_nilai_status
                                    ),
                                  }"
                                  :options="option10"
                                  disabled
                                  v-model="dataQC.p1d3_nilai"
                                  @change="
                                    submitQC('p1d3_nilai', dataQC.p1d3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Seluruh kegiatan inovasi masih berjalan hingga
                                  periode penilaian; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d3_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat bukti pemanfaatan seluruh kegiatan
                                  inovasi oleh masyarakat
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d3_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Sangat baik” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Cukup baik” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 4 -->

                          <tr>
                            <td rowspan="8">4</td>
                            <td rowspan="8">
                              Apakah ada ketentuan kegiatan monitoring dan
                              evaluasi?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya kegiatan monitoring dan
                              evaluasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option8"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d4_nilai',
                                      dataVerlap.p1d4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option8"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="6" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d4_nilai_status
                                    ),
                                  }"
                                  :options="option8"
                                  disabled
                                  v-model="dataQC.p1d4_nilai"
                                  @change="
                                    submitQC('p1d4_nilai', dataQC.p1d4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan monitoring dan evaluasi melibatkan
                                  <em>stakeholder</em> lain.;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi dalam bentuk
                                  fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d4_file6 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d4_file6" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d4_file6 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila keenam
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada tidak terjadwal” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 5 -->

                          <tr>
                            <td rowspan="6">5</td>
                            <td rowspan="6">
                              Apakah ada evaluasi dan tindak lanjut hasil
                              penilaian kinerja periode sebelumnya?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya evaluasi dan tindak lanjut
                              hasil penilaian kinerja periode sebelumnya
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d5_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat undangan resmi (melibatkan
                                  pihak eksternal) dalam bentuk fisik atau
                                  digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d5_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d5_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option3"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d5_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d5_nilai',
                                      dataVerlap.p1d5_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d5_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d5_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d5_nilai_status
                                    ),
                                  }"
                                  :options="option3"
                                  disabled
                                  v-model="dataQC.p1d5_nilai"
                                  @change="
                                    submitQC('p1d5_nilai', dataQC.p1d5_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d5_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d5_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d5_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d5_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat notula rapat kegiatan monitoring dan
                                  evaluasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d5_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d5_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d5_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumentasi rapat dalam bentuk foto digital.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d5_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d5_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila keempat ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 6 -->

                          <tr>
                            <td rowspan="6">6</td>
                            <td rowspan="6">
                              Apakah ada laporan kinerja pelayanan investasi?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya laporan berkala kinerja
                              pelayanan investasi dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d6_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dilakukan
                                  secara berkala sekurang-kurangnya setiap 3
                                  (tiga) bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d6_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d6_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option11"
                                  style="min-width: 150px"
                                  v-model="datapm.p1d6_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p1d6_nilai',
                                      dataVerlap.p1d6_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option11"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p1d6_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p1d6_nilai_status),
                                    'background-color': warna(
                                      dataQC.p1d6_nilai_status
                                    ),
                                  }"
                                  :options="option11"
                                  disabled
                                  v-model="dataQC.p1d6_nilai"
                                  @change="
                                    submitQC('p1d6_nilai', dataQC.p1d6_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d6_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat: <br />
                                  <table>
                                    <tr>
                                      <td>1)</td>
                                      <td>
                                        Jumlah perizinan berusaha yang terbit
                                        (untuk seluruh risiko);
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>2)</td>
                                      <td>
                                        Data rencana dan realisasi investasi.
                                      </td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d6_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d6_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d6_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi
                                  sekurang-kurangnya memuat kendala dan solusi;
                                  dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d6_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d6_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p1d6_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Laporan kinerja pelayanan investasi dibuat
                                  secara formal dalam bentuk dokumen laporan
                                  lengkap fisik atau digital, bukan bahan
                                  presentasi dan sejenisnya.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p1d6_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p1d6_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, berkala” apabila keempat
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada, tidak berkala” apabila salah
                                    satu dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_1_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_1_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_1_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_1_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Step 2-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan SDM PTSP berdasarkan
                          fakta yang ada dengan memilih data yang sesuai.
                          Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="card card-custom">
                      <div class="card-body">
                        <b-row align-h="between">
                          <b-col cols="4">
                            <!-- <b-button v-b-modal.modal-prevent-closing class="m-1"
                                                            style="background-color:#013368;color:white"><i
                                                                style="color:white;"
                                                                class="flaticon2-plus text-white"></i>Tambah Data
                                                            SDM</b-button> -->
                          </b-col>
                          <b-col cols="4">
                            <!-- <span style="width:150px; float:right"
                                                            class="label label-lg font-weight-bolder label-rounded label-success">Sudah
                                                            Diverifikasi</span> -->
                          </b-col>
                        </b-row>
                        <!-- <span class="badge text-bg-success">Sudah Diverifikasi</span> -->
                      </div>
                    </div>
                    <br />
                    <div class="card card-custom">
                      <div class="card-body">
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-1
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Dinas
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-1">
                              <div v-if="kelembagaan.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(lembaga, index) in kelembagaan"
                                      :key="index"
                                    >
                                      <td>{{ lembaga.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(lembaga)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="dataSDM.p2_kadis_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-1" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kadis_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kadis_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kadis_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kadis_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kadis_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-2
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Perizinan Berusaha/Pejabat
                              Fungsional Madya Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-2">
                              <div v-if="perizinanberusaha.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(pb, index) in perizinanberusaha"
                                      :key="index"
                                    >
                                      <td>{{ pb.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(pb)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="dataSDM.p2_kabidpb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-2" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kabidpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kabidpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kabidpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kabidpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kabidpb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-3
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Perizinan Berusaha/Pejabat
                              Fungsional Muda Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-3">
                              <div v-if="kepalaseksi.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(ks, index) in kepalaseksi"
                                      :key="index"
                                    >
                                      <td>{{ ks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(ks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  v-model="dataSDM.p2_kasiepb_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-3" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiepb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiepb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiepb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiepb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiepb_serppb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-4
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staff <em>Front Office</em> Bidang Perizinan
                              Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-4">
                              <div v-if="stafffoizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(sfoz, index) in stafffoizin"
                                      :key="index"
                                    >
                                      <td>{{ sfoz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(sfoz)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  v-model="dataSDM.p2_foffpb_jumlah"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-4" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffpb_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffpb_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffpb_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffpb_serbha"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    v-model="dataSDM.p2_foffpb_serppb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-5
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Perizinan
                              Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-5">
                              <div v-if="staffboizin.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(sboz, index) in staffboizin"
                                      :key="index"
                                    >
                                      <td>{{ sboz.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(sboz)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  id="input-small"
                                  style="text-align: center"
                                  v-model="dataSDM.p2_boffpb_jumlah"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-5" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffpb_serbpb"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="dataSDM.p2_boffpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="dataSDM.p2_boffpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="dataSDM.p2_boffpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    id="input-small"
                                    style="text-align: center"
                                    disabled
                                    v-model="dataSDM.p2_boffpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-6
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Perizinan Berusaha
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-6">
                              <div v-if="staffhd.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(staffhd, index) in staffhd"
                                      :key="index"
                                    >
                                      <td>{{ staffhd.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(staffhd)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  type="number"
                                  style="text-align: center"
                                  id="input-small"
                                  disabled
                                  v-model="dataSDM.p2_hdeskpb_jumlah"
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-6" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_hdeskpb_serbpb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_hdeskpb_pengalaman"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_hdeskpb_seross"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_hdeskpb_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    type="number"
                                    style="text-align: center"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_hdeskpb_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-7
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Bidang Pengendalian Pelaksanaan/Pejabat
                              Fungsional Madya Bidang Pengendalian Pelaksanaan
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-7">
                              <div v-if="kepaladalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(
                                        kepaladalaks, index
                                      ) in kepaladalaks"
                                      :key="index"
                                    >
                                      <td>{{ kepaladalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(kepaladalaks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  type="number"
                                  v-model="dataSDM.p2_kabiddal_jumlah"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-7" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="dataSDM.p2_kabiddal_serbpb"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="dataSDM.p2_kabiddal_pengalaman"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    v-model="dataSDM.p2_kabiddal_seross"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_kabiddal_serbha"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_kabiddal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 20px;
                                padding: 5px 0px 5px 0px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-8
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Kepala Seksi Bidang Pengendalian
                              Pelaksanaan/Pejabat Fungsional Muda Bidang
                              Pengendalian Pelaksanaan
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-8">
                              <div v-if="ksdalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(ksdalaks, index) in ksdalaks"
                                      :key="index"
                                    >
                                      <td>{{ ksdalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(ksdalaks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="dataSDM.p2_kasiedal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-8" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiedal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiedal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiedal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_kasiedal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_kasiedal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-9
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staff <em>Front Office</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-9">
                              <div v-if="sfodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(sfodalaks, index) in sfodalaks"
                                      :key="index"
                                    >
                                      <td>{{ sfodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(sfodalaks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="dataSDM.p2_foffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-9" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_foffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    type="number"
                                    id="input-small"
                                    disabled
                                    v-model="dataSDM.p2_foffdal_serppb"
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-10
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Back Office</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-10">
                              <div v-if="sbodalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(sbodalaks, index) in sbodalaks"
                                      :key="index"
                                    >
                                      <td>{{ sbodalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(sbodalaks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="dataSDM.p2_boffdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-10" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_boffdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <b-row align-h="between" class="mb-3">
                          <b-col cols="6">
                            <div
                              style="
                                background-color: #005fc1;
                                line-height: 38px;
                                min-height: 38px;
                                color: white;
                                text-align: center;
                                border-radius: 8px;
                              "
                              v-b-toggle.collapse-11
                              v-b-tooltip.hover
                              title="Lihat data"
                            >
                              Staf <em>Helpdesk</em> Bidang Dalaks
                              <i
                                style="
                                  line-height: 38px;
                                  min-height: 38px;
                                  color: white;
                                  float: right;
                                "
                                class="fa fa-angle-down mr-2"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <b-collapse id="collapse-11">
                              <div v-if="shddalaks.length != 0">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>Nama</th>
                                      <th>Lihat</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr
                                      v-for="(shddalaks, index) in shddalaks"
                                      :key="index"
                                    >
                                      <td>{{ shddalaks.nama_sdm }}</td>
                                      <td>
                                        <b-button
                                          variant="success"
                                          size="sm"
                                          data-toggle="modal"
                                          data-target="#editsdm"
                                          @click="getperSDM(shddalaks)"
                                        >
                                          <i class="flaticon-edit"></i>Lihat
                                        </b-button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </b-collapse>
                          </b-col>
                          <b-col cols="6">
                            <b-row class="my-1">
                              <b-col sm="8">
                                <label
                                  style="
                                    line-height: 38px;
                                    height: 38px;
                                    float: right;
                                    text-align: center;
                                  "
                                  for="input-small"
                                  >Jumlah SDM :</label
                                >
                              </b-col>
                              <b-col sm="4">
                                <b-form-input
                                  style="text-align: center"
                                  v-model="dataSDM.p2_hdeskdal_jumlah"
                                  type="number"
                                  id="input-small"
                                  disabled
                                ></b-form-input>
                              </b-col>
                            </b-row>
                            <b-collapse id="collapse-11" class="mt-2">
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bidang Perizinan Berusaha
                                    :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_hdeskdal_serbpb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Pengalaman Bidang :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_hdeskdal_pengalaman"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat OSS :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_hdeskdal_seross"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Bahasa Asing :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_hdeskdal_serbha"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                              <b-row class="my-1">
                                <b-col sm="8">
                                  <label
                                    style="
                                      line-height: 38px;
                                      height: 38px;
                                      float: right;
                                      text-align: center;
                                    "
                                    for="input-small"
                                    >Sertifikat Lainnya :</label
                                  >
                                </b-col>
                                <b-col sm="4">
                                  <b-form-input
                                    style="text-align: center"
                                    v-model="dataSDM.p2_hdeskdal_serppb"
                                    type="number"
                                    id="input-small"
                                    disabled
                                  ></b-form-input>
                                </b-col>
                              </b-row>
                            </b-collapse>
                          </b-col>
                        </b-row>
                        <!-- <button type="button" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Save</button> -->
                        <!--end: Datatable-->
                      </div>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_2_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_2_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_2_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_2_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 2-->

                  <!--begin: Wizard Step 3-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Sarpras PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Sarana dan Prasarana Utama Sistem Pelayanan
                                Perizinan Berusaha</b
                              >
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="5">1</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang front
                              office??
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kelayakan ruang
                              <em>front office</em>
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia ruangan/loket/meja dan petugas
                                  tersendiri.;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Ruang <em>front office</em> memanfaatkan
                                  teknologi informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Ruang <em>front office</em> terawat dan
                                  memadai.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">
                                    <strong>Informasi</strong>
                                  </div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <em>front office</em>?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang front office
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> memiliki jumlah yang
                                  memadai;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> memanfaatkan teknologi
                                  informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a2_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>front office</em> berfungsi dan layak
                                  digunakan.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a2_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->
                          <tr>
                            <td rowspan="5">3</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang
                              <em>back office</em>?
                            </td>
                            <td colspan="4">
                              Penilaian kecukupan dan kelayakan ruang
                              <em>back office</em>
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia ruang dan petugas tersendiri untuk
                                  <em>back office</em>;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Ruang <em>back office</em> memanfaatkan
                                  teknologi informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a3_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Ruang <em>back office</em> terawat dan
                                  memadai.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a3_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 4 -->
                          <tr>
                            <td rowspan="5">4</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang <em>back office</em>?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang
                              <em>back office</em> dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>back office</em> memiliki jumlah yang
                                  memadai;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a4_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>back office</em> memanfaatkan teknologi
                                  informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a4_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3a4_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  <em>back office</em> berfungsi dan layak
                                  digunakan.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3a4_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3a4_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- kewenangan -->
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Sarana dan Prasarana Pendukung</b>
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang pelayanan prioritas, ruang
                              pelayanan mandiri) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">Tersedia ruang rapat;</div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia ruang pelayanan prioritas;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia ruang pelayanan mandiri;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia sarana teknologi informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b1_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Ruangan pendukung terawat dan memadai.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b1_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 2 -->

                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Bagaimana kecukupan dan kelayakan ruang pendukung
                              (ruang rapat, ruang rapat prioritas, ruang
                              pelayanan mandiri)?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan dan kualitas sarana pendukung
                              pelayanan perizinan di ruang pendukung (meja,
                              kursi, komputer, <em>printer</em>, pendukung yang
                              relevan) dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memiliki jumlah yang memadai;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung memanfaatkan teknologi informasi;
                                  dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b2_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana pendukung pelayanan perizinan di ruang
                                  pendukung berfungsi dan layak digunakan.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b2_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->

                          <tr>
                            <td rowspan="7">3</td>
                            <td rowspan="7">
                              Bagaimana kecukupan dan kelayakan sarana informasi
                              (Identitas kantor, petunjuk arah, informasi
                              pelayanan)?
                            </td>
                            <td colspan="5">
                              Penilaian sarana informasi (Identitas kantor,
                              petunjuk arah dan informasi pelayanan) dilakukan
                              dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia identitas kantor;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b3_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia petunjuk arah dan ruangan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b3_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b3_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b3_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia informasi jenis-jenis pelayanan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b3_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b3_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b3_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Tersedia sarana teknologi informasi; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b3_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b3_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="4">
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p3b3_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-10">
                                  Sarana informasi terawat dan memadai.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p3b3_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p3b3_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Cukup dan layak” apabila kelima
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Tidak cukup dan layak” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_3_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_3_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_3_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_3_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 3-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Implementasi OSS PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">Hapus</th>
                            <th style="display: none">Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b
                                >Ketersediaan Standar Operasional
                                Prosedur(SOP)</b
                              >
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada SOP implementasi OSS?
                            </td>
                            <td colspan="4">
                              Penilaian ketersediaan SOP implementasi OSS
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP ditetapkan melalui Peraturan Kepala Daerah
                                  atau Peraturan Kepala DPMPTSP;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4a1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option3"
                                  style="min-width: 150px"
                                  v-model="datapm.p4a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p4a1_nilai',
                                      dataVerlap.p4a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option3"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p4a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p4a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p4a1_nilai_status
                                    ),
                                  }"
                                  :options="option3"
                                  disabled
                                  v-model="dataQC.p4a1_nilai"
                                  @change="
                                    submitQC('p4a1_nilai', dataQC.p4a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP merupakan ketentuan mengenai tata kelola
                                  penggunaan sistem OSS dalam pelayanan dan
                                  pengawasan perizinan berusaha sesuai
                                  kewenangannya;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4a1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4a1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP secara jelas mengatur peran OPD Teknis
                                  sebagai verifikator dan pengawas perizinan
                                  berusaha dan DPMPTSP sebagai pemberi
                                  persetujuan dan koordinator pengawasan
                                  perizinan berusaha;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4a1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4a1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4a1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP mencakup seluruh bidang usaha (KBLI) yang
                                  menjadi kewenangannya; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4a1_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4a1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4a1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  SOP dapat diakses oleh publik secara digital
                                  atau konvensional.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4a1_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4a1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">
                                    <strong>Informasi</strong>
                                  </div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kelima ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Tidak ada” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- Implementasi -->

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Implementasi</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="7">1</td>
                            <td rowspan="7">
                              Apakah ada kegiatan sosialisasi SOP implementasi
                              OSS?
                            </td>
                            <td colspan="5">
                              Penilaian ada tidaknya kegiatan sosialisasi SOP
                              implementasi OSS dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Sosialisasi SOP implementasi OSS dilakukan
                                  sekurang-kurangnya 1 kali dalam 3 bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option12"
                                  style="min-width: 150px"
                                  v-model="datapm.p4b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p4b1_nilai',
                                      dataVerlap.p4b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option12"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p4b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="5" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p4b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p4b1_nilai_status
                                    ),
                                  }"
                                  :options="option12"
                                  disabled
                                  v-model="dataQC.p4b1_nilai"
                                  @change="
                                    submitQC('p4b1_nilai', dataQC.p4b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia surat undangan resmi dalam bentuk
                                  fisik atau digital;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia daftar hadir peserta rapat dalam
                                  bentuk fisik atau digital; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia notula sosialisasi SOP implementasi;
                                  dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b1_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b1_file5 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumentasi rapat dalam bentuk foto
                                  digital
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b1_file5" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b1_file5 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada lebih dari 1 kali” apabila
                                    kelima ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada, 1 kali” apabila salah satu
                                    dari ketentuan di atas tidak terpenuhi.
                                    <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 3 -->
                          <tr>
                            <td rowspan="5">2</td>
                            <td rowspan="5">
                              Apakah ada kegiatan terjadwal pendampingan
                              implementasi OSS?
                            </td>
                            <td colspan="5">
                              Penilaian kecukupan kegiatan pendampingan
                              implementasi OSS dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Kegiatan pendampingan implementasi OSS
                                  dilakukan sekurang-kurangnya 1 kali dalam 1
                                  bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option8"
                                  style="min-width: 150px"
                                  v-model="datapm.p4b2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p4b2_nilai',
                                      dataVerlap.p4b2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option8"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p4b2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="3" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p4b2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p4b2_nilai_status
                                    ),
                                  }"
                                  :options="option8"
                                  disabled
                                  v-model="dataQC.p4b2_nilai"
                                  @change="
                                    submitQC('p4b2_nilai', dataQC.p4b2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b2_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia surat penugasan; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b2_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b2_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b2_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia dokumentasi kegiatan dalam bentuk
                                  laporan atau foto kegiatan.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b2_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b2_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, terjadwal” apabila ketiga
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada, tidak terjadwal” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 4 -->
                          <tr>
                            <td rowspan="3">3</td>
                            <td rowspan="3">
                              Bagaimana kinerja/hasil implementasi OSS?
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b3_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen permohonan Izin PM yang masuk dan
                                  tangkapan layar bukti pelayanan di OSS
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b3_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b3_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>

                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option13"
                                  style="min-width: 150px"
                                  v-model="datapm.p4b3_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p4b3_nilai',
                                      dataVerlap.p4b3_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option13"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p4b3_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p4b3_nilai_status),
                                    'background-color': warna(
                                      dataQC.p4b3_nilai_status
                                    ),
                                  }"
                                  :options="option13"
                                  disabled
                                  v-model="dataQC.p4b3_nilai"
                                  @change="
                                    submitQC('p4b3_nilai', dataQC.p4b3_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penilaian kinerja/hasil implementasi OSS
                                    dilakukan dengan ketentuan dapat menunjukkan
                                    permohonan pelayanan perizinan dalam sistem
                                    OSS. Untuk penetapan bobot penilaian: <br />
                                    1. Nilai “Semua Perizinan Berusaha
                                    menggunakan OSS” apabila seluruh permohonan
                                    perizinan berusaha yang masuk sesuai
                                    lampiran PP No. 5 Tahun 2021 yang menjadi
                                    kewenangannya dapat ditunjukkan di sistem
                                    OSS. <br />
                                    2. Nilai “Sebagian Perizinan Berusaha
                                    menggunakan OSS” apabila sebagian permohonan
                                    perizinan berusaha yang masuk sesuai
                                    lampiran PP No. 5 Tahun 2021 yang menjadi
                                    kewenangannya dapat ditunjukkan di sistem
                                    OSS.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <!-- 1 -->
                          <tr>
                            <td rowspan="4">4</td>
                            <td rowspan="4">
                              Apakah ada verifikasi perizinan berusaha dengan
                              risiko Menengah Tinggi (MT) dan Tinggi (T)?
                            </td>
                            <td colspan="5">
                              Penilaian adanya verifikasi perizinan berusaha
                              dengan risiko Menengah Tinggi (MT) dan Tinggi (T)
                              dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b4_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Pemerintah daerah melakukan verifikasi
                                  terhadap seluruh perizinan berusaha dengan
                                  risiko Menengah Tinggi (MT) dan Tinggi (T)
                                  yang diterima dari pelaku usaha; dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b4_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b4_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option14"
                                  style="min-width: 150px"
                                  v-model="datapm.p4b4_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p4b4_nilai',
                                      dataVerlap.p4b4_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option14"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p4b4_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p4b4_nilai_status),
                                    'background-color': warna(
                                      dataQC.p4b4_nilai_status
                                    ),
                                  }"
                                  :options="option14"
                                  disabled
                                  v-model="dataQC.p4b4_nilai"
                                  @change="
                                    submitQC('p4b4_nilai', dataQC.p4b4_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p4b4_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tidak ada dokumen terkait permohonan perizinan
                                  berusaha secara fisik yang wajib diserahkan
                                  oleh pelaku usaha kepada PTSP.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p4b4_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p4b4_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada” apabila kedua ketentuan di
                                    atas terpenuhi. <br />
                                    2. Nilai “Sebagian” apabila salah satu dari
                                    ketentuan di atas tidak terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_4_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_4_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_4_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_4_bkpm"></div>
                      </b-card>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Step 4-->
                  <div class="pb-5" data-wizard-type="step-content">
                    <div
                      style="background-color: #1bc5bd"
                      class="card card-custom"
                    >
                      <div class="card-body text-green">
                        <h3 class="card-label" style="color: white">
                          <i
                            class="fa fa-info-circle"
                            style="color: white"
                            aria-hidden="true"
                          ></i>
                          Penting!
                        </h3>
                        <hr />
                        <p style="color: white">
                          Input data yang berkaitan dengan Keluaran PTSP
                          berdasarkan fakta yang ada dengan memilih data yang
                          sesuai. Dokumen lampiran yang diunggah adalah
                          <strong style="color: yellow">halaman depan</strong>
                          dan
                          <strong style="color: yellow"
                            >halaman pengesahan</strong
                          >
                          dokumen SOP, peraturan, dan lainnya. Ukuran file setiap
                          dokumen maksimal
                          <strong style="color: yellow">500KB</strong> dengan
                          format file
                          <strong style="color: yellow">*.pdf</strong>.
                        </p>
                      </div>
                    </div>
                    <br />
                    <div class="table-responsive-sm">
                      <table class="table">
                        <thead>
                          <tr class="table-active">
                            <th style="display: none">No</th>
                            <th style="display: none">Indikator</th>
                            <th style="display: none">Jawaban</th>
                            <th style="display: none">Unggah</th>
                            <th style="display: none">Download</th>
                            <th style="display: none">hapus</th>
                            <th style="display: none">Verifikasi</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Realisasi Target Investasi</b>
                            </td>
                          </tr>
                          <!-- 1 -->
                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana tingkat realisasi target investasi?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5a1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen target investasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5a1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5a1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option15"
                                  style="min-width: 150px"
                                  v-model="datapm.p5a1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p5a1_nilai',
                                      dataVerlap.p5a1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option15"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p5a1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p5a1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p5a1_nilai_status
                                    ),
                                  }"
                                  :options="option15"
                                  disabled
                                  v-model="dataQC.p5a1_nilai"
                                  @change="
                                    submitQC('p5a1_nilai', dataQC.p5a1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5a1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen realisasi investasi
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5a1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5a1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">
                                    <strong>Informasi</strong>
                                  </div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Terlampaui atau tercapai” apabila
                                    tingkat realisasi investasi melebihi atau
                                    mencapai target yang ditetapkan. <br />
                                    2. Nilai “Tidak tercapai” apabila tingkat
                                    realiasasi investasi tidak mencapai target
                                    yang ditetapkan.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">2</td>
                            <td rowspan="3">
                              Berapa persen tingkat penyerapan dan pemanfaatan
                              Dana Alokasi Khusus (DAK)?
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5a2_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen alokasi DAK dan realisasinya
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5a2_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5a2_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option16"
                                  style="min-width: 150px"
                                  v-model="datapm.p5a2_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p5a2_nilai',
                                      dataVerlap.p5a2_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option16"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p5a2_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="1" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p5a2_nilai_status),
                                    'background-color': warna(
                                      dataQC.p5a2_nilai_status
                                    ),
                                  }"
                                  :options="option16"
                                  disabled
                                  v-model="dataQC.p5a2_nilai"
                                  @change="
                                    submitQC('p5a2_nilai', dataQC.p5a2_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penilaian tingkat penyerapan dan
                                    pemanfaatan Dana Alokasi Khusus (DAK)
                                    dilakukan dengan ketentuan memiliki dan
                                    dapat menunjukkan dokumen penyerapan dan
                                    pemanfaatan DAK. Penetapan bobot penilaian:
                                    <br />
                                    1. Nilai “80-100%” apabila penyerapan dan
                                    pemanfaatan DAK sekurang-kurangnya sebesar
                                    80% dari yang ditetapkan; <br />
                                    2. Nilai “60 – 79,99%” apabila penyerapan
                                    dan pemanfaatan DAK sekurang-kurangnya
                                    sebesar 60% dan sebanyak-banyaknya sebesar
                                    79,99% dari yang ditetapkan; <br />
                                    3. Nilai “40 – 59,99%” apabila penyerapan
                                    dan pemanfaatan DAK sekurang-kurangnya
                                    sebesar 40% sebanyak-banyaknya sebesar
                                    59,99% dari yang ditetapkan; dan <br />
                                    4. Nilai “di bawah 40%” apabila penyerapan
                                    dan pemanfaatan DAK sebanyak-banyaknya
                                    sebesar 40% dari yang ditetapkan.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Realisasi Target Penyerapan Tenaga Kerja</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="3">1</td>
                            <td rowspan="3">
                              Bagaimana realisasi target penyerapan tenaga
                              kerja?
                            </td>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5b1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen target penyerapan TK
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5b1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5b1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option15"
                                  style="min-width: 150px"
                                  v-model="datapm.p5b1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p5b1_nilai',
                                      dataVerlap.p5b1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option15"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p5b1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="2" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p5b1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p5b1_nilai_status
                                    ),
                                  }"
                                  :options="option15"
                                  disabled
                                  v-model="dataQC.p5b1_nilai"
                                  @change="
                                    submitQC('p5b1_nilai', dataQC.p5b1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5b1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Dokumen target penyerapan TK
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5b1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5b1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Terlampaui atau tercapai” apabila
                                    tingkat realisasi penyerapan tenaga kerja
                                    melebihi atau mencapai target yang
                                    ditetapkan. <br />
                                    2. Nilai “Tidak tercapai” apabila tingkat
                                    realisasi penyerapan tenaga kerja tidak
                                    mencapai target yang ditetapkan.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="7" style="background-color: #ebedf3">
                              <b>Fasilitasi terhadap Usaha Mikro Kecil (UMK)</b>
                            </td>
                          </tr>

                          <tr>
                            <td rowspan="6">1</td>
                            <td rowspan="6">
                              Apakah ada fasilitasi kemitraan dengan pelaku UMK
                              daerah?
                            </td>
                            <td colspan="5">
                              Penilaian fasilitasi kemitraan dengan pelaku UMK
                              daerah dilakukan dengan ketentuan:
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5c1_file1 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  dilakukan sekurang-kurangnya 1 kali dalam 3
                                  bulan;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5c1_file1" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5c1_file1 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Penilaian Mandiri">
                                <b-form-select
                                  variant="primary"
                                  :options="option17"
                                  style="min-width: 150px"
                                  v-model="datapm.p5c1_nilai"
                                  disabled
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Verifikasi Lapangan">
                                <a
                                  @click="
                                    submitQC(
                                      'p5c1_nilai',
                                      dataVerlap.p5c1_nilai
                                    )
                                  "
                                >
                                  <b-form-select
                                    variant="primary"
                                    :options="option17"
                                    style="min-width: 150px"
                                    v-model="dataVerlap.p5c1_nilai"
                                    disabled
                                  >
                                  </b-form-select>
                                </a>
                              </b-form-group>
                            </td>
                            <td rowspan="4" style="vertical-align: middle">
                              <b-form-group label="Quality Control">
                                <b-form-select
                                  variant="primary"
                                  :style="{
                                    'min-width': '150px',
                                    color: warnaText(dataQC.p5c1_nilai_status),
                                    'background-color': warna(
                                      dataQC.p5c1_nilai_status
                                    ),
                                  }"
                                  :options="option17"
                                  disabled
                                  v-model="dataQC.p5c1_nilai"
                                  @change="
                                    submitQC('p5c1_nilai', dataQC.p5c1_nilai)
                                  "
                                >
                                </b-form-select>
                              </b-form-group>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5c1_file2 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Terdapat surat tugas fasilitasi kemitraan
                                  dengan pelaku UMK daerah;
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5c1_file2" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5c1_file2 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5c1_file3 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Fasilitasi kemitraan dengan pelaku UMK daerah
                                  melibatkan pihak terkait
                                  (Perbankan/Asosiasi/Pelaku usaha
                                  Menengah-Besar); dan
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5c1_file3" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5c1_file3 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div class="row">
                                <div class="col-1">
                                  <i
                                    v-if="this.dataVerlap.p5c1_file4 == null"
                                    style="color: red"
                                    class="flaticon2-cross"
                                  >
                                  </i>
                                  <i
                                    v-else
                                    style="color: green"
                                    class="flaticon2-check-mark"
                                  >
                                  </i>
                                </div>
                                <div class="col-11">
                                  Tersedia laporan atau dokumentasi kegiatan
                                  fasilitasi kemitraan dalam bentuk foto/video
                                  digital.
                                </div>
                              </div>
                            </td>

                            <td>
                              <a :href="dataVerlap.p5c1_file4" target="_blank">
                                <button
                                  v-if="this.dataVerlap.p5c1_file4 != null"
                                  v-b-tooltip.hover
                                  title="Unduh"
                                  type="button"
                                  size="sm"
                                  class="btn btn-success text-uppercase btn-sm text-white"
                                >
                                  <i class="far fa-file-pdf"></i> Unduh File
                                </button>
                              </a>
                            </td>
                          </tr>

                          <tr>
                            <td colspan="5">
                              <div
                                style="background-color: #3699ff"
                                class="card text-white"
                              >
                                <div class="card-body">
                                  <div class="card-title">Informasi</div>
                                  <span
                                    >Penetapan bobot penilaian: <br />
                                    1. Nilai “Ada, teragenda” apabila keempat
                                    ketentuan di atas terpenuhi. <br />
                                    2. Nilai “Ada, tidak teragenda” apabila
                                    salah satu dari ketentuan di atas tidak
                                    terpenuhi. <br />
                                    3. Nilai “Tidak ada” apabila seluruh
                                    ketentuan di atas tidak terpenuhi.
                                  </span>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <hr />

                    <div v-if="pm.catatan_5_surveyor != null">
                      <b-card
                        border-variant="primary"
                        header-bg-variant="primary"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan Surveyor
                          </h5>
                        </template>
                        <div
                          class="card-text"
                          v-html="pm.catatan_5_surveyor"
                        ></div>
                      </b-card>
                    </div>
                    <br />
                    <div v-if="pm.catatan_5_bkpm != null">
                      <b-card
                        border-variant="danger"
                        header-bg-variant="danger"
                        header-text-variant="white"
                        align="left"
                      >
                        <template #header>
                          <h5>
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Catatan BKPM
                          </h5>
                        </template>
                        <div class="card-text" v-html="pm.catatan_5_bkpm"></div>
                      </b-card>
                    </div>
                  </div>

                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    style="margin-top: -40px"
                  >
                    <div class="my-5">
                      <div
                        style="background-color: #f4a902"
                        class="card card-custom"
                      >
                        <div class="card-body text-green">
                          <h3
                            class="card-label text-green"
                            style="color: white"
                          >
                            <i
                              class="fa fa-info-circle"
                              style="color: white"
                              aria-hidden="true"
                            ></i>
                            Persetujuan
                          </h3>
                          <hr />
                          <p style="color: white; font-size: 15px">
                            Kami dari pihak pendamping Quality Control (QC)
                            menyetujui hasil Verifikasi dan Validasi Akhir
                            Penilaian Mandiri yang telah dilaksanakan oleh tim
                            QC. Seluruh hasil Verifikasi dan Validasi Akhir
                            Penilaian Mandiri telah dicatat dan disimpan di
                            dalam Sistem Teknologi Informasi dan Komunikasi.
                          </p>
                          <div
                            v-if="
                              dataQC.file_ba == null || dataQC.file_ba == ''
                            "
                          >
                            <!-- profil.nama_kepala -->
                            <button
                              type="button"
                              v-if="user.id_role === 'pendamping_qc'"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              @click="persetujuan"
                              class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4"
                            >
                              <i
                                class="fa fa-check"
                                style="color: white"
                                aria-hidden="true"
                              ></i>
                              Persetujuan
                            </button>
                          </div>
                          <div v-else>
                            <a
                              :href="dataQC.file_ba"
                              target="_blank"
                              class="btn btn-primary"
                              style="
                                float: right;
                                background-color: #004d00;
                                border-color: #004d00;
                              "
                              ><i
                                class="fa fa-download"
                                aria-hidden="true"
                              />Unduh File BA</a
                            >
                            <!-- <br><br><a style="float: right; color:white" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-repeat" style="color:white" aria-hidden="true"/> Ulangi</a> -->
                            <!-- <button v-if="profil.nama_kepala == null || profil.nama_kepala == ''" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" @click="cekketua" class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                    <button v-else-if="vf.nama_surveyor == null" type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" hidden class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button>
                                    <button v-else type="button" style="float: right; background-color: #004d00; border-color: #004d00; margin-right:10px;" data-toggle="modal" data-target="#exampleModal" class="btn btn-primary btn-sm font-weight-bolder text-uppercase px-9 py-4">Persetujuan</button> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Step 4-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div class="mr-2">
                      <button
                        class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-prev"
                      >
                        Kembali
                      </button>
                    </div>
                    <div>
                      <button
                        v-on:click="submit"
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Selesai
                      </button>
                      <button
                        class="btn btn-primary font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-next"
                      >
                        Simpan sementara
                      </button>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->

                <!-- Modal tambah sdm -->
                <b-modal
                  v-model="show"
                  id="modal-prevent-closing"
                  ref="modal"
                  size="lg"
                  scrollable
                  title="Tambah Aspek Sumber Daya Manusia"
                >
                  <form
                    ref="form"
                    @submit.stop.prevent="handleSubmit"
                    data-vv-scope="form-1"
                  >
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Name is required"
                        >Nama Lengkap SDM
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="text"
                          v-validate="'required'"
                          name="Nama"
                          v-model="nama_sdm"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.has('form-1.Nama'),
                          }"
                          id="name"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Nama')"
                        >
                          {{ errors.first("form-1.Nama") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="NIP is required"
                        >NIP <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="number"
                          v-model="nip"
                          placeholder="Jika tidak memiliki NIP harap isi dengan angka 01, 02, dst"
                          v-validate="'required'"
                          name="NIP"
                          :class="{
                            'form-control': true,
                            'is-invalid': errors.has('form-1.NIP'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.NIP')"
                        >
                          {{ errors.first("form-1.NIP") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4" invalid-feedback="Bagian is required"
                        >Bagian <span style="color: red">*</span></label
                      >
                      <div class="col-8">
                        <b-form-select
                          v-model="jabatan"
                          required
                          :options="bagian"
                          v-validate="'required'"
                          name="Bagian"
                          :class="{ 'is-invalid': errors.has('form-1.Bagian') }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Bagian')"
                        >
                          {{ errors.first("form-1.Bagian") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Pendidikan is required"
                        >Pendidikan Terakhir, Bidang Ilmu
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="text"
                          v-model="pendidikan"
                          placeholder="S1, Teknik Informatika"
                          required
                          v-validate="'required'"
                          name="Pendidikan"
                          :class="{
                            'is-invalid': errors.has('form-1.Pendidikan'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Pendidikan')"
                        >
                          {{ errors.first("form-1.Pendidikan") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label
                        class="col-lg-4 col-form-label"
                        invalid-feedback="Tahun is required"
                        >Lama Bertugas di Bidang Terkait (Tahun)
                        <span style="color: red">*</span></label
                      >
                      <div class="col-lg-8">
                        <b-form-input
                          type="number"
                          v-model="lama_bertugas"
                          required
                          min="0"
                          v-validate="'required'"
                          name="Lama Bertugas"
                          :class="{
                            'is-invalid': errors.has('form-1.Lama Bertugas'),
                          }"
                        />
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.Lama Bertugas')"
                        >
                          {{ errors.first("form-1.Lama Bertugas") }}
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Bidang Perizinan Berusaha</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_bpb_dasar"
                          placeholder="Masukkan nomor sertifikat tingkat dasar"
                          class="form-control"
                        />
                        <br />
                        <input
                          type="text"
                          :disabled="
                            ser_bpb_dasar == null || ser_bpb_dasar == ''
                          "
                          v-model="ser_bpb_lanjutan"
                          placeholder="Masukkan nomor sertifikat tingkat lanjutan"
                          class="form-control"
                        />
                        <br />
                        <input
                          type="text"
                          :disabled="
                            ser_bpb_dasar == null || ser_bpb_dasar == ''
                          "
                          v-model="ser_bpb_sektoral"
                          placeholder="Masukkan nomor sertifikat tingkat sektoral"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat OSS</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_oss"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Bahasa Asing</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_bha"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >No. Sertifikat Lain Pendukung Pelayanan Perizinan
                        Berusaha</label
                      >
                      <div class="col-lg-8">
                        <input
                          type="text"
                          v-model="ser_ppb"
                          class="form-control"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >Format File Bukti</label
                      >
                      <div class="col-lg-8">
                        <a
                          :href="
                            this.url +
                            '/public/dokumen/pm_ptsp_2023/Lampiran_Bukti_SDM_Penkin 2023.docx'
                          "
                          target="_blank"
                          ><b-button variant="success">Download</b-button></a
                        >
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-lg-4 col-form-label"
                        >Unggah File Bukti</label
                      >
                      <div class="col-lg-8">
                        <b-form-file
                          v-model="filename"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                          v-validate="'required'"
                          name="File Bukti"
                          accept=".pdf"
                          :class="{
                            'is-invalid': errors.has('form-1.File Bukti'),
                          }"
                        ></b-form-file>
                        <span
                          class="text-danger"
                          v-if="errors.has('form-1.File Bukti')"
                        >
                          {{ errors.first("form-1.File Bukti") }}
                        </span>
                        <br />
                        <span
                          >Maksimal ukuran file 500 KB dengan format file
                          *.pdf</span
                        >
                      </div>
                    </div>
                  </form>
                  <template #modal-footer>
                    <div class="w-100">
                      <b-button
                        variant="primary"
                        size="sm"
                        class="float-right"
                        style="
                          background-color: #030a8c;
                          border-color: #030a8c;
                          color: white;
                        "
                        @click="handleSubmit"
                      >
                        Kirim
                      </b-button>

                      <b-button
                        variant="danger"
                        size="sm"
                        class="float-right"
                        style="margin-right: 10px"
                        @click="show = false"
                      >
                        Batal
                      </b-button>
                    </div>
                  </template>
                </b-modal>

                <!-- modal edit sdm -->
                <div
                  class="modal fade"
                  id="editsdm"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle">
                          Data SDM
                          <span class="text-primary">{{
                            getSDM.nama_sdm
                          }}</span>
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Nama SDM <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              disabled
                              type="text"
                              v-model="getSDM.nama_sdm"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >NIP <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              disabled
                              type="number"
                              v-model="getSDM.nip"
                              placeholder="Jika tidak memiliki NIP harap isi dengan angka 01, 02, dst"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-4"
                            >Bagian <span style="color: red">*</span></label
                          >
                          <div class="col-8">
                            <b-form-select
                              disabled
                              v-model="getSDM.jabatan"
                              :options="bagian"
                            ></b-form-select>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Pendidikan Terakhir, Bidang Ilmu
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getSDM.pendidikan"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >Lama Bertugas di Bidang Terkait (Tahun)
                            <span style="color: red">*</span></label
                          >
                          <div class="col-lg-8">
                            <input
                              type="number"
                              disabled
                              v-model="getSDM.lama_bertugas"
                              min="0"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bidang Perizinan Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getSDM.ser_bpb_dasar"
                              placeholder="Masukkan nomor sertifikat tingkat dasar"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              placeholder="Masukkan nomor sertifikat tingkat lanjutan"
                              class="form-control"
                            />
                            <br />
                            <input
                              type="text"
                              disabled
                              placeholder="Masukkan nomor sertifikat tingkat sektoral"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat OSS</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getSDM.ser_oss"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Bahasa Asing</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getSDM.ser_bha"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >No. Sertifikat Lain Pendukung Pelayanan Perizinan
                            Berusaha</label
                          >
                          <div class="col-lg-8">
                            <input
                              type="text"
                              disabled
                              v-model="getSDM.ser_ppb"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-lg-4 col-form-label"
                            >File Bukti</label
                          >
                          <div class="col-lg-8">
                            <a :href="getSDM.filename" target="_blank"
                              ><b-button variant="success"
                                >Download</b-button
                              ></a
                            >
                          </div>
                        </div>

                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            data-dismiss="modal"
                          >
                            Tutup
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>
  
<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-verval.scss";
</style>
  
  
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import axios from "axios";

import qs from "qs";

import VueLoading from "vuejs-loading-plugin";
import Vue from "vue";
import Button from "../vue-bootstrap/Button.vue";

Vue.use(VueLoading);

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: "Loading", // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: "rgb(255,255,255)", // set custom background
  classes: ["myclass"], // array, object or string
});

export default {
  components: { Button },
  name: "ptsppemda-detail",
  mounted() {
    this.loadProfile()
    this.$forceUpdate();
    // this.$store.dispatch(SET_BREADCRUMB, [
    //   { title: localStorage.getItem("nama_wilayah"), route: "qualitycontrol" },
    //   { title: "Quality Control" },
    // ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (e) {
      // validate the form and use below function to stop the wizard's step
      // wizardObj.stop();
    });
    wizard.on("change", function (e) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });

    this.loadVerlap();
    this.loadPM();
    this.loadQC();
    this.loadsdm();
    this.getlocation();
  },
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      id_ptsp: this.$route.params.id,
      tahun: new Date().getFullYear(),
      user: JSON.parse(localStorage.getItem("user")),
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        {
          value: "kabidpb",
          text: "Kepala Bidang Perizinan Berusaha/Pejabat Fungsional Madya Bidang Perizinan Berusaha",
        },
        {
          value: "kasiepb",
          text: "Kepala Seksi Bidang Perizinan Berusaha/Pejabat Fungsional Muda Bidang Perizinan Berusaha",
        },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        { value: "boffpb", text: "Staf Back-office Bidang Perizinan Berusaha" },
        { value: "hdeskpb", text: "Staf Help Desk Bidang Perizinan Berusaha" },
        {
          value: "kabiddal",
          text: "Kepala Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Madya Bidang Pengendalian Pelaksanaan",
        },
        {
          value: "kasiedal",
          text: "Kepala Seksi Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Muda Bidang Pengendalian Pelaksanaan",
        },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],

      optionsPilihan: [
        { value: "Belum QC", text: "Belum QC" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Tidak sesuai", text: "Tidak sesuai" },
      ],
      option1: [
        { value: 25, text: "Ada, lengkap" },
        { value: 10, text: "Ada, tidak lengkap" },
        { value: 0, text: "Tidak ada" },
      ],
      option2: [
        { value: 25, text: "Patuh" },
        { value: 0, text: "Tidak patuh" },
      ],
      option3: [
        { value: 25, text: "Ada" },
        { value: 0, text: "Tidak ada" },
      ],
      option4: [
        { value: 25, text: "Ada sepenuhnya" },
        { value: 10, text: "Ada sebagian" },
        { value: 0, text: "Tidak ada" },
      ],
      option5: [
        { value: 25, text: "Tidak ada" },
        { value: 0, text: "Ada" },
      ],
      option6: [
        { value: 25, text: "Semua selesai" },
        { value: 10, text: "Sebagian selesai" },
        { value: 0, text: "Tidak ada yang selesai" },
      ],
      option7: [
        { value: 25, text: "Lebih cepat dari SOP" },
        { value: 10, text: "Sesuai SOP" },
        { value: 0, text: "Tidak sesuai" },
      ],
      option8: [
        { value: 25, text: "Ada terjadwal" },
        { value: 10, text: "Ada tidak terjadwal" },
        { value: 0, text: "Tidak ada" },
      ],
      option9: [
        { value: 25, text: "Diatas 75" },
        { value: 10, text: "Dibawah 75" },
        { value: 0, text: "Tidak ada" },
      ],
      option10: [
        { value: 25, text: "Sangat baik" },
        { value: 10, text: "Cukup baik" },
        { value: 0, text: "Tidak ada" },
      ],
      option11: [
        { value: 25, text: "Ada berkala" },
        { value: 10, text: "Ada, tidak berkala" },
        { value: 0, text: "Tidak ada" },
      ],
      option12: [
        { value: 25, text: "Ada lebih dari 1 kali" },
        { value: 10, text: "Ada, 1 kali" },
        { value: 0, text: "Tidak ada" },
      ],
      option13: [
        { value: 25, text: "Semua perizinan menggunakan OSS" },
        { value: 10, text: "Sebagian perizinan" },
      ],
      option14: [
        { value: 25, text: "Ada" },
        { value: 10, text: "Sebagian" },
        { value: 0, text: "Tidak ada" },
      ],
      option15: [
        { value: 25, text: "Terlampaui atau tercapai" },
        { value: 10, text: "Tidak tercapai" },
      ],
      option16: [
        { value: 25, text: "80 sampai dengan 100 persen " },
        { value: 20, text: "60 sampai dengan 79,99 persen" },
        { value: 10, text: "40 sampai dengan 59,99 persen" },
        { value: 0, text: "dibawah persen" },
      ],
      option17: [
        { value: 25, text: "Ada, teragenda" },
        { value: 10, text: "Ada, tidak teragenda" },
        { value: 0, text: "Tidak ada" },
      ],
      kelembagaan: [],
      perizinanberusaha: [],
      kepalaseksi: [],
      stafffoizin: [],
      staffboizin: [],
      staffhd: [],
      kepaladalaks: [],
      ksdalaks: [],
      sfodalaks: [],
      sbodalaks: [],
      shddalaks: [],

      dataVerlap: {},
      datapm: {},
      dataQC: {},
      selectedFile: null,

      dataSDM: {},
      getSDM: {},

      nama_sdm: "",
      nip: "",
      pendidikan: "",
      lama_bertugas: "",
      ser_bpb_dasar: "",
      ser_bpb_lanjutan: "",
      ser_bpb_sektoral: "",
      ser_oss: "",
      ser_bha: "",
      ser_ppb: "",
      jabatan: "",
      filename: null,
      show: false,

      location: null,
      gettingLocation: false,
      errorStr: null,
      latitude: "",
      longtitude: "",
      pm: {},
    };
  },
  methods: {
    warna(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "blue";
      } else if (value == "Sudah QC") {
        return "green";
      } else {
        return "";
      }
    },
    warnaText(value) {
      if (value == "Sudah QC dengan perubahan") {
        return "white";
      } else if (value == "Sudah QC") {
        return "white";
      } else {
        return "black";
      }
    },
    persetujuan() {
      Swal.fire({
        title: "Perhatian",
        text: "Apakah Anda yakin ingin mempersetujui Berita Acara?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Setuju",
        cancelButtonText: "Tutup",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .put(
              this.url + "/qc_ptsp_pemda_2023_ba/" + this.dataQC.id,
              {
                _method: "patch",
              },
              {
                headers: {
                  xth: this.token,
                },
              }
            )
            .then((response) => {
              Swal.fire(
                "Berhasil",
                "Anda berhasil mempersetujui Berita Acara",
                "success"
              );
              this.loadQC();
              // localStorage.setItem('idpm', JSON.stringify(response.data.id))
              // console.log(response)
              return response;
            })
            .catch((error) => {
              Swal.fire(
                "Gagal",
                "Anda gagal mempersetujui Berita Acara",
                "danger"
              );
              // console.log(error)
              return error;
            });
        }
      });
    },

    submit: function (e) {
      e.preventDefault();
      this.$router.push("/dashboard");
      // this.$router.go(-1)
    },

    loadProfile() {
      axios
        .get(this.url + `/profils?filter=idptsp,=,` + this.$route.params.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.profil = response.data.data;
          this.$store.dispatch(SET_BREADCRUMB, [
            { title: this.profil.daerah },
            { title: "PTSP" },
            { title: "Quality Control" },
          ]);
        });
    },

    loadQC() {
      axios
        .get(
          this.url +
            "/qc_ptsp_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataQC = response.data.data[0];
          console.log(this.dataQC);
        });
    },
    loadPM() {
      axios
        .get(
          this.url +
            "/pm_ptsp_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.datapm = response.data.data[0];
        });
    },

    loadVerlap() {
      axios
        .get(
          this.url +
            "/verifikasi_ptsp_pemda_2023?filter=idptsp,=," +
            this.id_ptsp +
            ";tahun,=," +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataVerlap = response.data.data[0];
          console.log(this.dataVerlap);
        });
    },
    loadsdm() {
      // tabel sdm bawah
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,kadis`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kelembagaan = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,kabidpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.perizinanberusaha = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,kasiepb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepalaseksi = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,foffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.stafffoizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,boffpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffboizin = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,hdeskpb`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.staffhd = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,kabiddal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.kepaladalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,kasiedal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.ksdalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,foffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sfodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,boffdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.sbodalaks = response.data.data;
        });
      axios
        .get(
          this.url +
            `/data_sdm_2023?filter=idptsp,=,` +
            this.id_ptsp +
            `;jabatan,=,hdeskdal`,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.shddalaks = response.data.data;
        });

      // tabel sdm atas
      axios
        .get(
          this.url +
            `/verifikasi_ptsp_pemda_2023?filter=idptsp,=,` +
            this.id_ptsp,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.dataSDM = response.data.data[0];
        });
    },

    previewFiles(data) {
      // Form 1
      if (data == "p1a1_file1") {
        this.$refs.p1a1_file1.click();
      } else if (data == "p1a1_file2") {
        this.$refs.p1a1_file2.click();
      } else if (data == "p1a1_file3") {
        this.$refs.p1a1_file3.click();
      } else if (data == "p1a2_file1") {
        this.$refs.p1a2_file1.click();
      } else if (data == "p1a3_file1") {
        this.$refs.p1a3_file1.click();
      } else if (data == "p1a3_file2") {
        this.$refs.p1a3_file2.click();
      } else if (data == "p1a3_file3") {
        this.$refs.p1a3_file3.click();
      } else if (data == "p1a4_file1") {
        this.$refs.p1a4_file1.click();
      } else if (data == "p1a4_file2") {
        this.$refs.p1a4_file2.click();
      } else if (data == "p1b1_file1") {
        this.$refs.p1b1_file1.click();
      } else if (data == "p1b1_file2") {
        this.$refs.p1b1_file2.click();
      } else if (data == "p1b2_file1") {
        this.$refs.p1b2_file1.click();
      } else if (data == "p1c1_file1") {
        this.$refs.p1c1_file1.click();
      } else if (data == "p1c1_file2") {
        this.$refs.p1c1_file2.click();
      } else if (data == "p1c2_file1") {
        this.$refs.p1c2_file1.click();
      } else if (data == "p1c2_file1") {
        this.$refs.p1a2_file1.click();
      } else if (data == "p1c3_file1") {
        this.$refs.p1c3_file1.click();
      } else if (data == "p1c4_file1") {
        this.$refs.p1c4_file1.click();
      } else if (data == "p1c4_file2") {
        this.$refs.p1c4_file2.click();
      } else if (data == "p1c4_file3") {
        this.$refs.p1c4_file3.click();
      } else if (data == "p1d1_file1") {
        this.$refs.p1d1_file1.click();
      } else if (data == "p1d1_file2") {
        this.$refs.p1d1_file2.click();
      } else if (data == "p1d1_file3") {
        this.$refs.p1d1_file3.click();
      } else if (data == "p1d1_file4") {
        this.$refs.p1d1_file4.click();
      } else if (data == "p1d1_file5") {
        this.$refs.p1d1_file5.click();
      } else if (data == "p1d2_file1") {
        this.$refs.p1d2_file1.click();
      } else if (data == "p1d3_file1") {
        this.$refs.p1d3_file1.click();
      } else if (data == "p1d3_file2") {
        this.$refs.p1d3_file2.click();
      } else if (data == "p1d3_file3") {
        this.$refs.p1d3_file3.click();
      } else if (data == "p1d4_file1") {
        this.$refs.p1d4_file1.click();
      } else if (data == "p1d4_file2") {
        this.$refs.p1d4_file2.click();
      } else if (data == "p1d4_file3") {
        this.$refs.p1d4_file3.click();
      } else if (data == "p1d4_file4") {
        this.$refs.p1d4_file4.click();
      } else if (data == "p1d4_file5") {
        this.$refs.p1d4_file5.click();
      } else if (data == "p1d4_file6") {
        this.$refs.p1d4_file6.click();
      } else if (data == "p1d5_file1") {
        this.$refs.p1d5_file1.click();
      } else if (data == "p1d5_file2") {
        this.$refs.p1d5_file2.click();
      } else if (data == "p1d5_file3") {
        this.$refs.p1d5_file3.click();
      } else if (data == "p1d5_file4") {
        this.$refs.p1d5_file4.click();
      } else if (data == "p1d6_file1") {
        this.$refs.p1d6_file1.click();
      } else if (data == "p1d6_file2") {
        this.$refs.p1d6_file2.click();
      } else if (data == "p1d6_file3") {
        this.$refs.p1d6_file3.click();
      } else if (data == "p1d6_file4") {
        this.$refs.p1d6_file4.click();
      }

      // Form 3
      else if (data == "p3a1_file1") {
        this.$refs.p3a1_file1.click();
      } else if (data == "p3a1_file2") {
        this.$refs.p3a1_file2.click();
      } else if (data == "p3a1_file3") {
        this.$refs.p3a1_file3.click();
      } else if (data == "p3a2_file1") {
        this.$refs.p3a2_file1.click();
      } else if (data == "p3a2_file2") {
        this.$refs.p3a2_file2.click();
      } else if (data == "p3a2_file3") {
        this.$refs.p3a2_file3.click();
      } else if (data == "p3a3_file1") {
        this.$refs.p3a3_file1.click();
      } else if (data == "p3a3_file2") {
        this.$refs.p3a3_file2.click();
      } else if (data == "p3a3_file3") {
        this.$refs.p3a3_file3.click();
      } else if (data == "p3a4_file1") {
        this.$refs.p3a4_file1.click();
      } else if (data == "p3a4_file2") {
        this.$refs.p3a4_file2.click();
      } else if (data == "p3a4_file3") {
        this.$refs.p3a4_file3.click();
      } else if (data == "p3b1_file1") {
        this.$refs.p3b1_file1.click();
      } else if (data == "p3b1_file2") {
        this.$refs.p3b1_file2.click();
      } else if (data == "p3b1_file3") {
        this.$refs.p3b1_file3.click();
      } else if (data == "p3b1_file4") {
        this.$refs.p3b1_file4.click();
      } else if (data == "p3b1_file5") {
        this.$refs.p3b1_file5.click();
      } else if (data == "p3b2_file1") {
        this.$refs.p3b2_file1.click();
      } else if (data == "p3b2_file2") {
        this.$refs.p3b2_file2.click();
      } else if (data == "p3b2_file3") {
        this.$refs.p3b2_file3.click();
      } else if (data == "p3b3_file1") {
        this.$refs.p3b3_file1.click();
      } else if (data == "p3b3_file2") {
        this.$refs.p3b3_file2.click();
      } else if (data == "p3b3_file3") {
        this.$refs.p3b3_file3.click();
      } else if (data == "p3b3_file4") {
        this.$refs.p3b3_file4.click();
      } else if (data == "p3b3_file5") {
        this.$refs.p3b3_file5.click();
      }

      // Form 4
      else if (data == "p4a1_file1") {
        this.$refs.p4a1_file1.click();
      } else if (data == "p4a1_file2") {
        this.$refs.p4a1_file2.click();
      } else if (data == "p4a1_file3") {
        this.$refs.p4a1_file3.click();
      } else if (data == "p4a1_file4") {
        this.$refs.p4a1_file4.click();
      } else if (data == "p4a1_file5") {
        this.$refs.p4a1_file5.click();
      } else if (data == "p4b1_file1") {
        this.$refs.p4b1_file1.click();
      } else if (data == "p4b1_file2") {
        this.$refs.p4b1_file2.click();
      } else if (data == "p4b1_file3") {
        this.$refs.p4b1_file3.click();
      } else if (data == "p4b1_file4") {
        this.$refs.p4b1_file4.click();
      } else if (data == "p4b1_file5") {
        this.$refs.p4b1_file5.click();
      } else if (data == "p4b2_file1") {
        this.$refs.p4b2_file1.click();
      } else if (data == "p4b2_file2") {
        this.$refs.p4b2_file2.click();
      } else if (data == "p4b2_file3") {
        this.$refs.p4b2_file3.click();
      } else if (data == "p4b3_file1") {
        this.$refs.p4b3_file1.click();
      } else if (data == "p4b4_file1") {
        this.$refs.p4b4_file1.click();
      } else if (data == "p4b4_file2") {
        this.$refs.p4b4_file2.click();
      }

      // Form 5
      else if (data == "p5a1_file1") {
        this.$refs.p5a1_file1.click();
      } else if (data == "p5a1_file2") {
        this.$refs.p5a1_file2.click();
      } else if (data == "p5a2_file1") {
        this.$refs.p5a2_file1.click();
      } else if (data == "p5b1_file1") {
        this.$refs.p5b1_file1.click();
      } else if (data == "p5b1_file2") {
        this.$refs.p5b1_file2.click();
      } else if (data == "p5c1_file1") {
        this.$refs.p5c1_file1.click();
      } else if (data == "p5c1_file2") {
        this.$refs.p5c1_file2.click();
      } else if (data == "p5c1_file3") {
        this.$refs.p5c1_file3.click();
      } else if (data == "p5c1_file4") {
        this.$refs.p5c1_file4.click();
      }
    },
    onFileChanged(e, data) {
      console.log(data);
      this.selectedFile = e.target.files[0];
      var fsize = this.selectedFile.size;
      var file = Math.round(fsize / 1024);
      // The size of the file.
      console.log(file);
      if (file >= 500) {
        console.log(file);

        this.$bvToast.toast("File terlalu besar, pilih file maksimal 500KB", {
          title: `Failed `,
          variant: `danger`,
          solid: true,
        });
        this.selectedFile = null;
      } else {
        this.$loading(true);
        let formData = new FormData();
        formData.append(data, this.selectedFile);
        axios
          .post(
            this.url + "/verifikasi_ptsp_pemda_2023/" + this.dataVerlap.id,
            formData,
            {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$loading(false);
            this.$bvToast.toast(response.data.message, {
              title: `Success `,
              variant: `success`,
              solid: true,
            });
            // console.log(response);
            this.loadVerlap();

            return response;
          })
          .catch((error) => {
            console.log(error);
            this.$loading(false);
            this.$bvToast.toast(error.response.data.message, {
              title: `Failed `,
              variant: `danger`,
              solid: true,
            });
            return error;
          });
      }
    },

    submitNilaiRadio(data, value) {
      let formData = new FormData();
      formData.append(data, value.target.value);
      axios
        .post(
          this.url + "/verifikasi_ptsp_pemda_2023/" + this.dataVerlap.id,
          formData,
          {
            // _method: 'patch',
            headers: {
              xth: this.token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          // this.$loading(false);
          // this.$bvToast.toast('Berhasil Verifikasi', {
          //     title: `Success `,
          //     variant: `success`,
          //     solid: true
          // })
          // console.log(response);
          this.loadVerlap();

          return response;
        })
        .catch((error) => {
          // console.log(error)
          // this.$loading(false);
          // this.$bvToast.toast('Gagal Verifikasi', {
          //     title: `Failed `,
          //     variant: `danger`,
          //     solid: true
          // })
          return error;
        });
    },

    submitQC(data, value) {
      let formData = new FormData();
      formData.append(data, value);
      // axios
      //     .post(this.url + '/qc_ptsp_pemda_2023/' + this.dataQC.id, formData, {
      //         // _method: 'patch',
      //         headers: {
      //             'xth': this.token,
      //             'Content-Type': 'multipart/form-data'
      //         }
      //     })
      //     .then(response => {
      //         this.$loading(false);
      //         this.$bvToast.toast('Berhasil Quality Control', {
      //             title: `Success `,
      //             variant: `success`,
      //             solid: true
      //         })
      //         // console.log(response);
      //         this.loadVerlap()
      //         this.loadPM()
      //         this.loadQC()

      //         return response

      //     })
      //     .catch(error => {
      //         console.log(error)
      //         this.$loading(false);
      //         this.$bvToast.toast('Gagal Quality Control', {
      //             title: `Failed `,
      //             variant: `danger`,
      //             solid: true
      //         })
      //         return error
      //     })
    },

    hapusFile(data) {
      // let formData = new FormData();
      // formData.append('field', data);
      // formData.append('idptsp', this.dataVerlap.idptsp);
      // formData.append('tahun', this.tahun);

      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan file ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus File",
      }).then((result) => {
        if (result.isConfirmed) {
          const formData = {
            field: data,
            idptsp: this.dataVerlap.idptsp,
            tahun: this.tahun,
          };
          axios
            .put(
              this.url + "/verifikasi_ptsp_pemda_2023_delete_lampiran",
              qs.stringify(formData),
              {
                // _method: 'patch',
                headers: {
                  xth: this.token,
                  "content-type": "application/x-www-form-urlencoded",
                },
              }
            )
            .then((response) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil hapus file", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              // console.log(response);
              this.loadVerlap();

              return response;
            })
            .catch((error) => {
              console.log(error);
              this.$loading(false);
              this.$bvToast.toast("Gagal hapus file", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return error;
            });
        }
      });
    },

    handleSubmit() {
      this.$validator.validateAll("form-1").then((result) => {
        if (result) {
          console.log("tetst");
          this.$nextTick(() => {
            this.$bvModal.hide("modal-prevent-closing");
          });
          this.$loading(true);

          let formData = new FormData();
          formData.append("idptsp", this.id_ptsp);
          formData.append("nama_sdm", this.nama_sdm);
          formData.append("nip", this.nip);
          formData.append("pendidikan", this.pendidikan);
          formData.append("jabatan", this.jabatan);
          formData.append("lama_bertugas", this.lama_bertugas);
          formData.append("ser_bpb_dasar", this.ser_bpb_dasar);
          formData.append("ser_bpb_lanjutan", this.ser_bpb_lanjutan);
          formData.append("ser_bpb_sektoral", this.ser_bpb_sektoral);
          formData.append("ser_oss", this.ser_oss);
          formData.append("ser_bha", this.ser_bha);
          formData.append("ser_ppb", this.ser_ppb);
          formData.append("filename", this.filename);
          axios
            .post(this.url + "/data_sdm_2023", formData, {
              // _method: 'patch',
              headers: {
                xth: this.token,
                "Content-Type": "multipart/form-data",
              },
            })
            .then((res) => {
              this.$loading(false);
              this.$bvToast.toast("Berhasil Disimpan", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });

              this.generateSDM();
              this.nama_sdm = null;
              this.nip = null;
              this.pendidikan = null;
              this.jabatan = null;
              this.lama_bertugas = null;
              this.ser_bpb_dasar = null;
              this.ser_bpb_lanjutan = null;
              this.ser_bpb_sektoral = null;
              this.ser_oss = null;
              this.ser_bha = null;
              this.ser_ppb = null;
              this.filename = null;
              return res;
            })
            .catch((err) => {
              this.$loading(false);
              console.log(err);
              this.$bvToast.toast("Gagal Disimpan", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });

      // Push the name to submitted names
      // this.submittedNames.push(this.name)
      // Hide the modal manually
    },

    getperSDM(data) {
      this.getSDM = data;
    },

    editsdm(id) {
      this.$nextTick(() => {
        this.$bvModal.hide("editsdm");
      });
      let formData = new FormData();
      formData.append("idptsp", this.id_ptsp);
      formData.append("nama_sdm", this.getSDM.nama_sdm);
      formData.append("nip", this.getSDM.nip);
      formData.append("pendidikan", this.getSDM.pendidikan);
      formData.append("jabatan", this.getSDM.jabatan);
      formData.append("lama_bertugas", this.getSDM.lama_bertugas);
      formData.append("ser_bpb_dasar", this.getSDM.ser_bpb_dasar);
      formData.append("ser_bpb_lanjutan", this.getSDM.ser_bpb_lanjutan);
      formData.append("ser_bpb_sektoral", this.getSDM.ser_bpb_sektoral);
      formData.append("ser_oss", this.getSDM.ser_oss);
      formData.append("ser_bha", this.getSDM.ser_bha);
      formData.append("ser_ppb", this.getSDM.ser_ppb);
      formData.append("filename", this.getSDM.file);
      axios
        .post(this.url + "/data_sdm_2023/" + id, formData, {
          // _method: 'patch',
          headers: {
            xth: this.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.$loading(false);
          this.$bvToast.toast("Berhasil Disimpan", {
            title: `Success `,
            variant: `success`,
            solid: true,
          });

          // console.log(res);
          this.generateSDM();
          return res;
        })
        .catch((err) => {
          this.$loading(false);
          console.log(err);
          this.$bvToast.toast("Gagal Disimpan", {
            title: `Failed `,
            variant: `danger`,
            solid: true,
          });
          return err;
        });
    },

    hapussdm(id) {
      Swal.fire({
        title: "Apakah Anda yakin?",
        text: "Anda tidak bisa mengembalikan data ini",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(this.url + `/data_sdm_2023/${id}`, {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            })
            .then((res) => {
              this.$bvToast.toast("Berhasil Dihapus", {
                title: `Success `,
                variant: `success`,
                solid: true,
              });
              this.generateSDM();

              return res;
            })
            .catch((err) => {
              // console.log(err)
              this.loadsdm();
              this.$bvToast.toast("Gagal Dihapus", {
                title: `Failed `,
                variant: `danger`,
                solid: true,
              });
              return err;
            });
        }
      });
    },

    getlocation() {
      if (!("geolocation" in navigator)) {
        this.errorStr = "Geolocation is not available.";
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.location = pos;
          this.latitude = pos.coords.latitude;
          this.longtitude = pos.coords.longitude;
          console.log(this.latitude);
          console.log(this.longtitude);
        },
        (err) => {
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },
  },
};
</script>
  
<style scoped>
@media only screen and (max-width: 760px),
  (min-device-width: 768px) and (max-device-width: 768px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  th,
  td,
  tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    margin: 10px 1rem 1rem 1rem;
  }

  /* tr:nth-child(odd) {
      background: #E1EDFF;
    } */

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    /* padding-left: 50%; */
  }

  th {
    display: none;
  }

  #selectVerifikasi > option {
    color: #000;
    background-color: #ffffff;
  }

  /* td:before { */
  /* Now like a table header */
  /* position: absolute; */
  /* Top/left values mimic padding */
  /* top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap; */
  /* } */

  /*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
  /* td:nth-of-type(1):before { content: "No"; }
		td:nth-of-type(2):before { content: "Indikator"; }
		td:nth-of-type(3):before { content: "Status"; }
		td:nth-of-type(4):before { content: "Jawaban"; }
        td:nth-of-type(5):before { content: "Unggah"; } 
        td:nth-of-type(6):before { content: "Download"; }  */
}
</style>